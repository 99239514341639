import api from "../utils/api";
import {
  GET_HOMEPAGEBANNERS,
  TOTAL_HOMEPAGEBANNERS,
  ADD_HOMEPAGEBANNER,
  GET_HOMEPAGEBANNER,
  RESET_HOMEPAGEBANNER,
  HOMEPAGEBANNERS_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countHomepagebanner =
  ({ q, searchParams, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      let query = "/homepage-banners/count?";
      if (q) {
        let newQ = ``;
        newQ += `&_q=${q}`;
        query = query + newQ;
      }
      if (searchParams && searchParams.length > 0) {
        let newQ = ``;
        searchParams.map((item) => {
          newQ += `&${item.label}=${item.value}`;
        });
        query = query + newQ;
      }
      if (createdAt_lte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
        query = query + newCreatedAt_gte;
      }
      if (createdAt_gte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
        query = query + newCreatedAt_gte;
      }

      let res = await api.get(query);
      dispatch({
        type: TOTAL_HOMEPAGEBANNERS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: HOMEPAGEBANNERS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };

// Get posts
export const getHomepagebanners =
  ({
    limit = 10,
    searchParams,
    page = 1,
    sort = "DESC",
    q,
    createdAt_lte,
    createdAt_gte,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_HOMEPAGEBANNER,
      });
      if (page) {
        const start = (page - 1) * limit;
        let query = `/homepage-banners?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
        if (q) {
          const newQ = `&_q=${q}`;
          query = query + newQ;
        }
        if (searchParams && searchParams.length > 0) {
          let newQ = ``;
          searchParams.map((item) => {
            newQ += `&${item.label}=${item.value}`;
          });
          query = query + newQ;
        }
        if (createdAt_lte) {
          const newLTE = `&createdAt_lte=${createdAt_lte}`;
          query = query + newLTE;
        }
        if (createdAt_gte) {
          const newGTE = `&createdAt_gte=${createdAt_gte}`;
          query = query + newGTE;
        }

        const res = await api.get(query);

        dispatch({
          type: GET_HOMEPAGEBANNERS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: HOMEPAGEBANNERS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };
export const getHomepagebannerBySlug = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_HOMEPAGEBANNER,
    });
    const res = await api.get("/homepage-banners?slug=" + id);
    if (res.data && res.data.length > 0) {
      dispatch({
        type: GET_HOMEPAGEBANNER,
        payload: res.data[0],
      });
    } else {
      dispatch(setAlert("No Homepagebanner Found", "danger"));
    }
  } catch (err) {
    dispatch({
      type: HOMEPAGEBANNERS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
// Get posts
export const getHomepagebanner = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_HOMEPAGEBANNER,
    });
    const res = await api.get("/homepage-banners/" + id);

    dispatch({
      type: GET_HOMEPAGEBANNER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: HOMEPAGEBANNERS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const addHomepagebanner = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/homepage-banners`, data);

    dispatch(setAlert("Homepagebanner Saved!", "success"));
    dispatch({
      type: ADD_HOMEPAGEBANNER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: HOMEPAGEBANNERS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const editHomepagebanner = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/homepage-banners/" + id, data);

    dispatch(setAlert("Homepagebanner Updated!", "success"));
  } catch (err) {
    dispatch({
      type: HOMEPAGEBANNERS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const deleteHomepagebanner = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_HOMEPAGEBANNER,
    });
    const res = await api.delete("/homepage-banners/" + id);

    dispatch(setAlert("Homepagebanner Deleted!", "success"));
  } catch (err) {
    dispatch({
      type: HOMEPAGEBANNERS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
