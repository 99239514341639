import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import Sliders from "../components/Sliders";

import { getProductcategorys } from "../actions/productcategorys";
import { getProducts } from "../actions/products";
import { URI } from "../constants/constants";
import Footer from "../components/Footer";
const ProductCategoryArchieve = ({
  getProductcategorys,
  productcategory: { loading, productcategorys },
  product: { products },
  match,
  getProducts,
}) => {
  useEffect(() => {
    if (!productcategorys) {
      getProductcategorys({ limit: 100 });
    }
  }, []);
  const [activeProducts, setActiveProducts] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);
  const [activeBtn, setActiveBtn] = useState("ALL");
  useEffect(() => {
    if (match.params.slug && productcategorys) {
      const filterData = productcategorys.filter(
        (item) => item.slug === match.params.slug
      );
      if (filterData && filterData.length > 0) {
        setActiveCategory(filterData[0]);
      }
      const filteredDataCat = productcategorys.filter(
        (item) =>
          item.parent_category &&
          item.parent_category.slug === match.params.slug
      );
      if (filteredDataCat && filteredDataCat.length) {
        setActiveSubcategory(filteredDataCat);
      }

      getProducts({
        limit: 200,
        searchParams: [
          { label: "product_category.slug", value: match.params.slug },
        ],
      });
    }
  }, [match.params.slug, productcategorys]);
  //   console.log("Active ", activeBtn);

  useEffect(() => {
    if (activeBtn && products) {
      if (activeBtn != "ALL") {
        console.log("ACTIVE BTN", activeBtn);
        const filterProducts = products.filter(
          (item) =>
            item.sub_product_category &&
            item.sub_product_category.id == activeBtn
        );
        console.log("FILTERED PRODUCT", filterProducts);
        if (filterProducts) {
          setActiveProducts(filterProducts);
        }
      }
      if (activeBtn === "ALL") {
        setActiveProducts(products);
      }
    }
  }, [products, activeBtn]);

  return (
    <div>
      <Menu />
      <div
        className="shop-wrapper "
        style={{
          background: "rgba(0, 0, 0, 0.6)",
          padding: "100px 0px 0px 0px",
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="page-title text-center">
              {activeCategory && activeCategory.name}
            </div>
          </div>
        </div>
        {activeSubcategory && (
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <div
                  className={
                    activeBtn === "ALL"
                      ? "shop-button active-shop-button"
                      : "shop-button"
                  }
                >
                  <a onClick={() => setActiveBtn("ALL")}>All</a>
                </div>
                {activeSubcategory &&
                  activeSubcategory.map((item) => {
                    return (
                      <div
                        className={
                          activeBtn === item.id
                            ? "shop-button active-shop-button"
                            : "shop-button"
                        }
                      >
                        <a onClick={() => setActiveBtn(item.id)}>{item.name}</a>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}

        <div className="row">
          {activeProducts &&
            activeProducts.map((product) => {
              return (
                <div className="col-md-4 no-padding">
                  <div className="content">
                    <Link to={`/product/${product.slug}`}>
                      <div className="content-overlay"></div>
                      <img
                        className="content-image"
                        src={
                          product.featured_image
                            ? `${URI}${product.featured_image.url}`
                            : "/images/IMG-20160705-WA0003.jpg"
                        }
                      />
                      <div className="content-details fadeIn-bottom">
                        <h3 className="content-title">{product.name}</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  productcategory: state.productcategory,
  product: state.product,
});

const mapDispatchToProps = { getProductcategorys, getProducts };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCategoryArchieve);
