import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

const OurMedia = ({}) => {
  return (
    <div>
      <Menu />
      <div style={{ background: "rgba(0,0,0,0.6)" }}>
        {/* Title */}

        {/* /Title */}
        {/* Content */}
        <section className="wrapper-team">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title text-center">Our Media</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <iframe
                  src="https://www.youtube.com/embed/R1_sbaNVzaE"
                  width={"100%"}
                  height={282}
                  frameBorder={0}
                  allowFullScreen="allowfullscreen"
                />
              </div>

              <div className="col-sm-4">
                <iframe
                  src="https://www.youtube.com/embed/o6giiU74kBA"
                  width={"100%"}
                  height={282}
                  frameBorder={0}
                  allowFullScreen="allowfullscreen"
                />
              </div>

              <div className="col-sm-4">
                <iframe
                  src="https://www.youtube.com/embed/Pnu2zOECNUs"
                  width={"100%"}
                  height={282}
                  frameBorder={0}
                  allowFullScreen="allowfullscreen"
                />
              </div>
            </div>
          </div>
        </section>
        {/* /Content */}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OurMedia);
