import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

const Privacy = ({}) => {
  return (
    <div>
      <Menu />
      <div style={{ background: "rgba(0,0,0,0.6)" }}>
        {/* Title */}

        {/* /Title */}
        {/* Content */}
        <section className="wrapper-team">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title text-center">Privacy Policy</div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="privacy-page">
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      This Privacy Policy governs the manner in which{" "}
                    </span>
                    <strong>INTERIOR CRAFT</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      and its subsidiaries, partners, agents and affiliates
                      collect, use, maintain and disclose information collected
                      from users of the INTERIOR CRAFT.
                    </span>
                  </p>
                  <p>
                    <strong>Personal identification information:</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      We may collect personal identification information from
                      Users in a variety of ways, including, but not limited to,
                      when
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Users visit our site, subscribe to the newsletter, fill
                        out a form, and in connection with other activities,
                        services, features or resources we make available on our
                        Site.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Users may be asked for, as appropriate, name, email
                        address, mailing address, phone number.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Users may, however, visit our Site anonymously.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Users can always refuse to supply personal
                        identification information, except that it may prevent
                        them from engaging in certain Site related activities.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <strong>Non-personal identification information: </strong>
                    <span style={{ fontWeight: 400 }}>
                      We may collect non-personal identification information
                      about Users whenever they interact with our Site.
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Non-personal identification information may include the
                        browser name, the type of computer and technical
                        information about Users means the type of connection to
                        our Site, such as the operating system and the Internet
                        service providers utilized and other similar
                        information.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <strong>Web browser cookies:</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      Our Site may use "cookies" to enhance User experience.
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        User's web browser places cookies on their hard drive
                        for record-keeping purposes and sometimes to track
                        information about them.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        User may choose to set their web browser to refuse
                        cookies, or to alert the Users when cookies are being
                        sent. If they do so, note that some parts of the Site
                        may not function properly.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <strong>How we use collected information:</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      INTERIOR CRAFT may collect and use User's personal
                      information for the following purposes:
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <strong>To improve customer service:</strong>
                      <span style={{ fontWeight: 400 }}>
                        {" "}
                        Information provided by Users helps us respond to the
                        customer service requests and support needs, more
                        efficiently.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <strong>To personalize User experience:</strong>
                      <span style={{ fontWeight: 400 }}>
                        {" "}
                        We may use information in the aggregate to understand
                        how our Users as a group use the services and resources
                        provided on our Site.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <strong>To improve our Site:</strong>
                      <span style={{ fontWeight: 400 }}>
                        {" "}
                        We may use feedback provided by the User/s to improve
                        our products and services.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        To run a promotion, contest, survey or other Site
                        feature.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        To send the User/s information they agreed to receive
                        about topics of interest to them.
                      </span>
                    </li>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        To send periodic emails.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <span style={{ fontWeight: 400 }}>
                      We may use the email address to respond to the inquiries,
                      questions, and/or other requests of User/s. If User/s
                      decide/s to opt to be part of our mailing list, then the
                      User/s will receive emails about company news, updates,
                      related product or service information, etc. If at any
                      time the User/s would like to unsubscribe from receiving
                      future emails, they may do so by contacting us via our
                      Site.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>How we protect User's information:</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      We adopt appropriate data collection, storage and
                      processing practices and security measures to protect
                      against unauthorized access, alteration, disclosure or
                      destruction of User's personal information and data stored
                      on our Site.
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        Sensitive and private data exchange between the Site and
                        its Users happens over an SSL secured communication
                        channel and is encrypted and protected with digital
                        signatures.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <strong>Sharing personal information of User/s:</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      We do not sell, trade, or rent User's personal
                      identification information to others. We may share generic
                      aggregated demographic information not linked to any
                      personal identification information regarding User/s with
                      our subsidiaries, our business partners, trusted
                      affiliates and advertisers for the purposes outlined
                      above.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>Changes to this privacy policy:</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      INTERIOR CRAFT shall update this privacy policy at its
                      sole discretion, the date of updating would reflect on
                      this page. Users are advised to check this page for any
                      changes in the privacy policy and to stay informed about
                      how the personal information of the Users is protected by
                      us.
                    </span>
                  </p>
                  <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}>
                      <span style={{ fontWeight: 400 }}>
                        The User/s hereby acknowledge/s and agree/s that it is
                        their responsibility to review this privacy policy
                        periodically and become aware of modifications.
                      </span>
                    </li>
                  </ul>
                  <p>&nbsp;</p>
                  <p>
                    <strong>Your acceptance of these terms:</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      By using this Site, the Users signify their acceptance of
                      this policy as may be modified from time to time. The
                      Users are advised not to access this site if they do not
                      agree to our privacy policy. The above-mentioned privacy
                      policy shall be applicable for the information and data
                      collected by our call centers as well.
                    </span>
                  </p>
                  <p>&nbsp;</p>
                  <p>
                    <strong>Contacting us:</strong>
                    <span style={{ fontWeight: 400 }}>
                      {" "}
                      If you have any questions about this Privacy Policy, the
                      practices of this site, or your dealings with this site,
                      please contact us at: INTERIOR CRAFT
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Content */}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
