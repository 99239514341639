import {
  GET_HOMEPAGEBANNERS,
  TOTAL_HOMEPAGEBANNERS,
  ADD_HOMEPAGEBANNER,
  EDIT_HOMEPAGEBANNER,
  GET_HOMEPAGEBANNER,
  RESET_HOMEPAGEBANNER,
  HOMEPAGEBANNERS_ERROR,
} from "../actions/types";

const initialState = {
  homepagebanners: null,
  homepagebanner: null,
  total_homepagebanners: 0,
  loading: true,
  error: {},
  homepagebanner_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_HOMEPAGEBANNERS:
      return {
        ...state,
        homepagebanners: payload,
        loading: false,
        error: {},
      };

    case TOTAL_HOMEPAGEBANNERS:
      return {
        ...state,
        total_homepagebanners: payload,
        loading: false,
        error: {},
      };
    case RESET_HOMEPAGEBANNER:
      return {
        ...state,
        homepagebanner: null,
        loading: true,
        error: {},
      };

    case ADD_HOMEPAGEBANNER:
      return {
        ...state,
        homepagebanner_message: payload,
        loading: false,
        error: {},
      };
    case GET_HOMEPAGEBANNER:
      return {
        ...state,
        homepagebanner: payload,
        loading: false,
        error: {},
      };
    case EDIT_HOMEPAGEBANNER:
      return {
        ...state,
        homepagebanner_message: payload,
        loading: false,
        error: {},
      };

    case HOMEPAGEBANNERS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
