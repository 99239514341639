import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import cart from "./cart";
import product from "./product";
import homepagebanner from "./homepagebanner";
import projectcategory from "./projectcategory";
import project from "./project";
import productcategory from "./productcategory";
import variation from "./variation";
import gallery from "./gallery";
import mobilebanner from "./mobilebanner";
export default combineReducers({
  auth,
  alert,
  cart,
  product,
  homepagebanner,
  projectcategory,
  project,
  productcategory,
  variation,
  gallery,
  mobilebanner,
});
