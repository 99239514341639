import {
  GET_PROJECTCATEGORYS,
  TOTAL_PROJECTCATEGORYS,
  ADD_PROJECTCATEGORY,
  EDIT_PROJECTCATEGORY,
  GET_PROJECTCATEGORY,
  RESET_PROJECTCATEGORY,
  PROJECTCATEGORYS_ERROR,
} from "../actions/types";

const initialState = {
  projectcategorys: null,
  projectcategory: null,
  total_projectcategorys: 0,
  loading: true,
  error: {},
  projectcategory_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROJECTCATEGORYS:
      return {
        ...state,
        projectcategorys: payload,
        loading: false,
        error: {},
      };

    case TOTAL_PROJECTCATEGORYS:
      return {
        ...state,
        total_projectcategorys: payload,
        loading: false,
        error: {},
      };
    case RESET_PROJECTCATEGORY:
      return {
        ...state,
        projectcategory: null,
        loading: true,
        error: {},
      };

    case ADD_PROJECTCATEGORY:
      return {
        ...state,
        projectcategory_message: payload,
        loading: false,
        error: {},
      };
    case GET_PROJECTCATEGORY:
      return {
        ...state,
        projectcategory: payload,
        loading: false,
        error: {},
      };
    case EDIT_PROJECTCATEGORY:
      return {
        ...state,
        projectcategory_message: payload,
        loading: false,
        error: {},
      };

    case PROJECTCATEGORYS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
