import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getProjectcategorys } from "../../actions/projectcategorys";
import { URI } from "../../constants/constants";

const CategoryGrid = ({
  getProjectcategorys,
  projectcategory: { projectcategorys, loading },
}) => {
  useEffect(() => {
    if (!projectcategorys) {
      getProjectcategorys({ limit: 100 });
    }
  }, []);
  return (
    <section className="work">
      <div className="grid-container">
        {projectcategorys &&
          projectcategorys.map((item, index) => {
            return (
              <div>
                <Link to={`/category/${item.slug}`} className={"item1 onhover"}>
                  <div
                    className="work-image"
                    style={{
                      backgroundImage: item.featured_image
                        ? `url(${URI}${item.featured_image.url})`
                        : 'url("/images/furniture.png")',
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="overlay" />
                    <div className="work-content">
                      <h2> {item.name} </h2>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({ projectcategory: state.projectcategory });

const mapDispatchToProps = { getProjectcategorys };

export default connect(mapStateToProps, mapDispatchToProps)(CategoryGrid);
