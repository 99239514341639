import {
  GET_GALLERYS,
  TOTAL_GALLERYS,
  ADD_GALLERY,
  EDIT_GALLERY,
  GET_GALLERY,
  RESET_GALLERY,
  GALLERYS_ERROR,
} from "../actions/types";

const initialState = {
  gallerys: null,
  gallery: null,
  total_gallerys: 0,
  loading: true,
  error: {},
  gallery_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_GALLERYS:
      return {
        ...state,
        gallerys: payload,
        loading: false,
        error: {},
      };

    case TOTAL_GALLERYS:
      return {
        ...state,
        total_gallerys: payload,
        loading: false,
        error: {},
      };
    case RESET_GALLERY:
      return {
        ...state,
        gallerys: null,
        gallery: null,
        loading: true,
        error: {},
      };

    case ADD_GALLERY:
      return {
        ...state,
        gallery_message: payload,
        loading: false,
        error: {},
      };
    case GET_GALLERY:
      return {
        ...state,
        gallery: payload,
        loading: false,
        error: {},
      };
    case EDIT_GALLERY:
      return {
        ...state,
        gallery_message: payload,
        loading: false,
        error: {},
      };

    case GALLERYS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
