import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import { getProductBySlug } from "../actions/products";
import QuotationForm from "../components/product/QuotationForm";
import { getVariations } from "../actions/variations";
import { URI } from "../constants/constants";
import productcategory from "../reducers/productcategory";
import ProductVariation from "../components/product/ProudctVariation";

const SingleProduct = ({
  match,
  getProductBySlug,
  getVariations,
  history,
  product: { loading, product },
  variation: { variations },
}) => {
  useEffect(() => {
    getProductBySlug(match.params.slug);
  }, [match.params.slug]);
  const [showNavMenu, setShowNavMenu] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [activeMenuType, setActiveMenuType] = useState(0);
  const [isactiveMenuTypeOpen, setIsActiveMenuTypeOpen] = useState(false);
  const [openQuote, setOpenQuote] = useState(false);
  const [activeImage, setActiveImage] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [level2Variation, setLevel2Variation] = useState(null);
  const [isLevel2VairationOpen, setIsLevel2VairationOpen] = useState(false);
  const [level3Variation, setLevel3Variation] = useState(null);
  const [isLevel3VairationOpen, setIsLevel3VairationOpen] = useState(false);
  const [level4Variation, setLevel4Variation] = useState(null);
  const [isLevel4VairationOpen, setIsLevel4VairationOpen] = useState(false);
  const [level5Variation, setLevel5Variation] = useState(null);
  const [isLevel5VairationOpen, setIsLevel5VairationOpen] = useState(false);

  useEffect(() => {
    if (product) {
      if (product.featured_image) {
        setActiveImage(`${URI}${product.featured_image.url}`);
      }

      // getVariations({
      //   limit: 100,
      //   searchParams: [{ label: "product.id", value: product.id }],
      // });
    }
  }, [product]);
  console.log("Level 2 Variations", level2Variation);

  const handleLevel1Options = ({ product, index }) => {
    setActiveImage(product.pic);
    setActiveSubMenu(activeMenuType);
    setLevel2Variation(product);
    setLevel3Variation(null);
    setLevel4Variation(null);
    setLevel5Variation(null);
  };
  const handleLevel2Options = ({ product, index }) => {
    setActiveImage(product.pic);
    setActiveSubMenu(activeMenuType);
    setLevel3Variation(product);
    setLevel4Variation(null);
    setLevel5Variation(null);
  };
  const handleLevel3Options = ({ product, index }) => {
    setActiveImage(product.pic);
    setActiveSubMenu(activeMenuType);
    setLevel4Variation(product);
    setLevel5Variation(null);
  };
  const handleLevel4Options = ({ product, index }) => {
    setActiveImage(product.pic);
    setActiveSubMenu(activeMenuType);
    setLevel5Variation(product);
  };

  return (
    <div>
      {showNavMenu && <Menu />}

      <div className="projects">
        {product && (
          <section className="project" tabIndex={-1}>
            {/* Gallery */}
            {showNavMenu && (
              <div className="circle-rel" id="showmenu">
                <div className="circle-ab">
                  <div
                    className="circle-button"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenMenu(!openMenu)}
                  >
                    <i className="fa fa-gear fa-spin" />
                  </div>
                </div>
              </div>
            )}

            <div
              className="sidebar-single"
              style={{ display: openMenu ? "block" : "none" }}
            >
              <div className="close-options">
                <i
                  className="fa fa-close"
                  onClick={() => setOpenMenu(false)}
                ></i>
              </div>
              <div className="filters-content">
                <div className="project-name">
                  <h3> {product.name} </h3>
                </div>
                <ul>
                  {product.product_variations &&
                    product.product_variations.map(
                      (variation, variation_index) => {
                        return (
                          <li>
                            <a
                              href="#"
                              onClick={() => {
                                setActiveMenuType(variation_index);
                                setIsActiveMenuTypeOpen(!isactiveMenuTypeOpen);
                              }}
                            >
                              {variation.variation_title}
                              {activeMenuType == variation_index &&
                              isactiveMenuTypeOpen ? (
                                <i className="fa fa-chevron-down" />
                              ) : (
                                <i className="fa fa-chevron-right" />
                              )}
                            </a>
                            <div
                              className="pdt-50"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="drowdown"
                                style={{
                                  display:
                                    activeMenuType === variation_index &&
                                    isactiveMenuTypeOpen
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <div>
                                  {variation && (
                                    <div>
                                      {activeMenuType == variation_index &&
                                        isactiveMenuTypeOpen && (
                                          <div className="whit-flex">
                                            {variation.variations &&
                                              variation.variations.map(
                                                (
                                                  sub_variation,
                                                  level_2_index
                                                ) => {
                                                  return (
                                                    <ProductVariation
                                                      product={sub_variation}
                                                      setActiveImage={
                                                        setActiveImage
                                                      }
                                                      setVariation={
                                                        setLevel2Variation
                                                      }
                                                      setActiveSubMenu={
                                                        setActiveSubMenu
                                                      }
                                                      variation_index={
                                                        variation_index
                                                      }
                                                      handleClick={() =>
                                                        handleLevel1Options({
                                                          product:
                                                            sub_variation,
                                                          index: level_2_index,
                                                        })
                                                      }
                                                    />
                                                  );
                                                }
                                              )}
                                          </div>
                                        )}
                                    </div>
                                  )}
                                  {activeSubMenu === variation_index &&
                                    level2Variation &&
                                    level2Variation.variations && (
                                      <div>
                                        <div>
                                          <a href="#" onClick={() => {}}>
                                            {level2Variation.variation_title}
                                            {activeMenuType &&
                                            activeMenuType.main_menu ===
                                              variation.name ? (
                                              <i className="fa fa-chevron-down" />
                                            ) : (
                                              <i className="fa fa-chevron-right" />
                                            )}
                                          </a>
                                        </div>
                                        <div className="whit-flex">
                                          {level2Variation.variations &&
                                            level2Variation.variations.map(
                                              (
                                                sub_variation,
                                                level_2_index
                                              ) => {
                                                return (
                                                  <ProductVariation
                                                    product={sub_variation}
                                                    setActiveImage={
                                                      setActiveImage
                                                    }
                                                    setVariation={
                                                      setLevel2Variation
                                                    }
                                                    setActiveSubMenu={
                                                      setActiveSubMenu
                                                    }
                                                    variation_index={
                                                      variation_index
                                                    }
                                                    handleClick={() =>
                                                      handleLevel2Options({
                                                        product: sub_variation,
                                                        index: level_2_index,
                                                      })
                                                    }
                                                  />
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  {activeSubMenu === variation_index &&
                                    level2Variation &&
                                    level2Variation.variations &&
                                    level3Variation &&
                                    level3Variation.variations && (
                                      <div>
                                        <div>
                                          <a
                                            href="#"
                                            onClick={() =>
                                              setActiveMenuType({
                                                main_menu: variation.name,
                                              })
                                            }
                                          >
                                            {level3Variation.variation_title}
                                            {activeMenuType &&
                                            activeMenuType.main_menu ===
                                              variation.name ? (
                                              <i className="fa fa-chevron-down" />
                                            ) : (
                                              <i className="fa fa-chevron-right" />
                                            )}
                                          </a>
                                        </div>
                                        <div className="whit-flex">
                                          {level3Variation.variations &&
                                            level3Variation.variations.map(
                                              (
                                                sub_variation,
                                                level_3_index
                                              ) => {
                                                return (
                                                  <ProductVariation
                                                    product={sub_variation}
                                                    setActiveImage={
                                                      setActiveImage
                                                    }
                                                    setVariation={
                                                      setLevel2Variation
                                                    }
                                                    setActiveSubMenu={
                                                      setActiveSubMenu
                                                    }
                                                    variation_index={
                                                      variation_index
                                                    }
                                                    handleClick={() =>
                                                      handleLevel3Options({
                                                        product: sub_variation,
                                                        index: level_3_index,
                                                      })
                                                    }
                                                  />
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  {activeSubMenu === variation_index &&
                                    level2Variation &&
                                    level2Variation.variations &&
                                    level3Variation &&
                                    level3Variation.variations &&
                                    level4Variation &&
                                    level4Variation.variations && (
                                      <div>
                                        <div>
                                          <a
                                            href="#"
                                            onClick={() =>
                                              setActiveMenuType({
                                                main_menu: variation.name,
                                              })
                                            }
                                          >
                                            {level4Variation.variation_title}
                                            {activeMenuType &&
                                            activeMenuType.main_menu ===
                                              variation.name ? (
                                              <i className="fa fa-chevron-down" />
                                            ) : (
                                              <i className="fa fa-chevron-right" />
                                            )}
                                          </a>
                                        </div>
                                        <div className="whit-flex">
                                          {level4Variation.variations &&
                                            level4Variation.variations.map(
                                              (
                                                sub_variation,
                                                level_4_index
                                              ) => {
                                                return (
                                                  <ProductVariation
                                                    product={sub_variation}
                                                    setActiveImage={
                                                      setActiveImage
                                                    }
                                                    setVariation={
                                                      setLevel2Variation
                                                    }
                                                    setActiveSubMenu={
                                                      setActiveSubMenu
                                                    }
                                                    variation_index={
                                                      variation_index
                                                    }
                                                    handleClick={() =>
                                                      handleLevel4Options({
                                                        product: sub_variation,
                                                        index: level_4_index,
                                                      })
                                                    }
                                                  />
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )}
                </ul>
              </div>
            </div>
            {/* right side  */}

            <div className="button-xpand">
              <a
                href="#"
                onClick={() => {
                  setOpenMenu(false);
                  setShowNavMenu(!showNavMenu);
                }}
              >
                <div className="expand-btn">
                  {!showNavMenu ? (
                    <i className="fa fa-close" />
                  ) : (
                    <i className="fa fa-expand" />
                  )}
                </div>
              </a>
            </div>

            {activeImage && (
              <figure className="project__gallery" style={{ display: "block" }}>
                <div
                  className="image-sidebar"
                  style={{
                    backgroundImage: `url(${activeImage})`,
                  }}
                />
              </figure>
            )}

            <div className="right-arrow-rel" style={{ position: "relative" }}>
              <div className="left-arrow-ab" />
              <div className="right-arrow-ab">
                <div className="quote-button">
                  <a onClick={() => setOpenQuote(true)}>Get A Quote</a>
                </div>
              </div>
            </div>
            <div className="clearfix" />
          </section>
        )}
      </div>
      <QuotationForm
        openQuote={openQuote}
        setOpenQuote={setOpenQuote}
        activeImage={activeImage}
      />
      <div className="mobileonly">
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.product,
  variation: state.variation,
});

const mapDispatchToProps = { getProductBySlug, getVariations };

export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
