import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import Sliders from "../components/Sliders";
import { getGalleryBySlug } from "../actions/gallerys";
import { URI } from "../constants/constants";

const SingleProductGallery = ({
  getGalleryBySlug,
  gallery: { gallery },
  match,
}) => {
  useEffect(() => {
    getGalleryBySlug(match.params.slug);
  }, [match.params.slug]);
  return (
    <div>
      <Menu />
      <div style={{ background: "rgba(0,0,0,0.6)" }}>
        <section style={{ paddingTop: "100px" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title text-center">
                  {gallery && gallery.name}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="panel-list">
          {gallery &&
            gallery.gallery &&
            gallery.gallery.map((item) => {
              return (
                <div class="content">
                  <div class="content-overlay"></div>
                  <img class="content-image" src={`${URI}${item.url}`} />
                </div>
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ gallery: state.gallery });

const mapDispatchToProps = { getGalleryBySlug };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleProductGallery);
