import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import Sliders from "../components/Sliders";

const AboutUs = () => {
  return (
    <div>
      <Menu />
      <div className="infopage infopage_about">
        {/* Title */}
        <div className="infopage__title infopage__title_about">
          <a href="/overview#about" className="infopage__title__back" />
          <h1 className="infopage__title__text infopage__title__text_about">
            About
          </h1>
        </div>
        {/* /Title */}
        {/* Content */}
        <section className="infopage__content">
          <div className="infopage__columns">
            <div className="infopage__columns__wrap">
              <div className="infopage__columns__column infopage__columns__column_intro">
                <div className="row">
                  <div className="col-md-5 align-item-center">
                    <img
                      src="/images/team/42.JPG"
                      style={{
                        position: "sticky",
                        top: "75px",
                        maxWidth: "100%",
                        borderRadius: "10px",
                        boxShadow: "rgba(100, 100, 111, 0.6) 0px 7px 29px 0px",
                      }}
                    />
                  </div>
                  <div className="col-md-7">
                    <div className="site-inner1">
                      <p>
                        Interior Craft is specialized in manufacturing
                        engineered products, design and&nbsp;
                        <strong>
                          installation of residential main gates, railings,
                          shower Cabins, spiders and stair construction for
                          Residence, Commercial, Industrial, Health Care,
                          Educational, Governmental work
                        </strong>
                        &nbsp;and so on as we believe in creating difference, by
                        our innovative ideas which then converted into design to
                        make a new product.
                      </p>
                      <p>
                        Our motto is to provide a complete high quality finished
                        product for your dream project&rsquo;s architectural
                        division .Interior Craft noted for ensuring elegant
                        execution of the most innovative and artful designs and
                        its uniqueness in the market. We are launching our brand
                        New product in the market named Shower Cabin which has
                        got uniqueness by its structure.
                      </p>
                      <p>
                        The organizations segments include sustainability,
                        workforce relations, and business intelligence with
                        honesty.&nbsp;
                        <strong>
                          Company has been certified as an ISO: 9001:2008.
                        </strong>
                        &nbsp;Our innovative, self designed and fabricated
                        products improve the quality of life with a new passion
                        of style. The Interior Craft is uniquely committed to
                        serving our clients and giving the best service with in
                        the industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-content">
              <div className="row">
                <div className="col-md-6">
                  <h3>Vision </h3>
                  <p>
                    <i className="fa fa-angle-right"></i> To grow continuously
                    with innovative vision and ideas to cater most architectural
                    needs, while providing peace of mind to our esteemed
                    clientele.
                  </p>
                </div>
                <div className="col-md-6">
                  <h3>Mission</h3>
                  <p>
                    <i className="fa fa-angle-right"></i> To create out of the
                    box solutions for different architectural tangents
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i> To get recognised
                    nationally and globally by our Innovative product range
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h3>Core Values</h3>
                  <p>
                    <i className="fa fa-angle-right"></i> Honest work ethics
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i> Innovation &
                    Creativity
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i> Build longterm
                    relations with architects and the clients
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i> Continuous
                    Improvements
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i> Fulfilling Commitments
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i> Attention To Detail
                  </p>
                </div>
                <div className="col-md-6">
                  <h3>Goal</h3>
                  <p>
                    <i className="fa fa-angle-right"></i> Strive to achieve the
                    highest quality and dignity in both the process and products
                    of professional work
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i> Continuously work
                    towards achieving modern and unconventional finishes for our
                    products
                  </p>
                  <p>
                    <i className="fa fa-angle-right"></i> To incorporate latest
                    machinery in the production process for precision and
                    refinement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Content */}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
