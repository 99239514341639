import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

const OurTeam = ({}) => {
  return (
    <div>
      <Menu />
      <div style={{ background: "rgba(0,0,0,0.6)" }}>
        {/* Title */}

        {/* /Title */}
        {/* Content */}
        <section className="wrapper-team">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title text-center">Our Team</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="team-card">
                  <img
                    src="/images/team/anis-ahmad1-1.jpg"
                    alt="Interior Craft"
                  />
                  <p className="team-title"> Anis Ahmed </p>
                  <p className="team-post">CEO & Founder </p>
                </div>
              </div>
                         <div className="col-md-3">
                <div className="team-card">
                  <img src="/images/team/Adil-1.jpg" alt="Interior Craft" />
                  <p className="team-title"> Aadil Saifi</p>
                  <p className="team-post">
                    CBDM (Chief Business Development Manager)
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="team-card">
                  <img src="/images/team/shahzeb.jpg" alt="Interior Craft" />
                  <p className="team-title"> Shahzeb Ahmed</p>
                  <p className="team-post">
                    CCO (Chief Communications Officer)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Content */}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OurTeam);
