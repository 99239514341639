import {
  GET_VARIATIONS,
  TOTAL_VARIATIONS,
  ADD_VARIATION,
  EDIT_VARIATION,
  GET_VARIATION,
  RESET_VARIATION,
  VARIATIONS_ERROR,
} from "../actions/types";

const initialState = {
  variations: null,
  variation: null,
  total_variations: 0,
  loading: true,
  error: {},
  variation_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_VARIATIONS:
      return {
        ...state,
        variations: payload,
        loading: false,
        error: {},
      };

    case TOTAL_VARIATIONS:
      return {
        ...state,
        total_variations: payload,
        loading: false,
        error: {},
      };
    case RESET_VARIATION:
      return {
        ...state,
        variations: null,
        variation: null,
        loading: true,
        error: {},
      };

    case ADD_VARIATION:
      return {
        ...state,
        variation_message: payload,
        loading: false,
        error: {},
      };
    case GET_VARIATION:
      return {
        ...state,
        variation: payload,
        loading: false,
        error: {},
      };
    case EDIT_VARIATION:
      return {
        ...state,
        variation_message: payload,
        loading: false,
        error: {},
      };

    case VARIATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
