import React, { useState, useEffect } from "react";
import { sliders } from "../modals/sliders";
import { connect } from "react-redux";
import { getHomepagebanners } from "../actions/homepagebanners";
import { URI } from "../constants/constants";
function Sliders({
  getHomepagebanners,
  homepagebanner: { loading, homepagebanners },
}) {
  const [activeItem, setActiveItem] = useState(0);
  const [activeHover, setActiveHover] = useState(false);
  useEffect(() => {
    if (!homepagebanners) {
      getHomepagebanners({ limit: 100 });
    }
  }, []);
  useEffect(() => {
    if (homepagebanners) {
      const interval = setInterval(() => {
        setActiveItem((activeItem) =>
          activeItem < homepagebanners.length - 1 ? activeItem + 1 : 0
        );
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [homepagebanners]);

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0].clientX);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0].clientX);
  }

  function handleTouchEnd() {
    if (touchStart - touchEnd > 75) {
      // do your stuff here for left swipe
      // moveSliderRight();
      console.log("SLIDE left");
      setActiveItem((activeItem) =>
        activeItem < homepagebanners.length - 1 ? activeItem + 1 : 0
      );
    }

    if (touchStart - touchEnd < -75) {
      // do your stuff here for right swipe
      console.log("SLIDE RIGHT");
      setActiveItem((activeItem) => (activeItem > 0 ? activeItem - 1 : 0));
      // moveSliderLeft();
    }
  }
  console.log("TOUCH START", touchStart);
  console.log("TOUCH END", touchEnd);
  return (
    <section className={activeHover ? "promo promo_next" : "promo"}>
      {/* content */}
      <div
        className="promo__content"
        onTouchStart={(touchStartEvent) => handleTouchStart(touchStartEvent)}
        onTouchMove={(touchMoveEvent) => handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => handleTouchEnd()}
      >
        {/* item */}
        {homepagebanners &&
          homepagebanners.map((slider, index) => {
            return (
              <a
                key={`slider-${index}`}
                className={
                  index === activeItem
                    ? "promo__item promo__item_light promo__item_active"
                    : index === activeItem + 1
                    ? "promo__item promo__item_light promo__item_next"
                    : "promo__item promo__item_light promo__item_far"
                }
              >
                <span className="promo__item__bg">
                  <i
                    className="promo__item__bg__picture"
                    style={{
                      backgroundImage: `url(${URI}${slider.featured_image.url})`,
                    }}
                  />
                </span>
                <span className="promo__item__about">
                  <span
                    className="promo__item__about__content"
                    style={{
                      background: "rgba(0,0,0,0.4)",
                      padding: "20px",
                      width: "350px",
                    }}
                  >
                    <strong className="promo__item__about__title">
                      {slider.heading}
                    </strong>
                    <div className="arrow-icon">
                      <div>
                        <img src="/images/right-arrow.png" alt="" />
                      </div>
                      <div className="about-craft">{slider.content}</div>
                    </div>
                  </span>
                </span>
              </a>
            );
          })}

        {/* item */}
      </div>
      {/* next */}
      <div
        className="promo__next"
        onClick={() =>
          setActiveItem(
            activeItem < homepagebanners.length - 1 ? activeItem + 1 : 0
          )
        }
        onMouseEnter={() => setActiveHover(true)}
        onMouseLeave={() => setActiveHover(false)}
      />
      {/* mouse */}
      <div className="ui-mouse">
        <i className="ui-mouse__wheel" />
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({ homepagebanner: state.homepagebanner });

const mapDispatchToProps = { getHomepagebanners };

export default connect(mapStateToProps, mapDispatchToProps)(Sliders);
