import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getProductcategorys } from "../actions/productcategorys";
import { getProjectcategorys } from "../actions/projectcategorys";

const Footer = ({
  getProductcategorys,
  getProjectcategorys,
  projectcategory: { projectcategorys, loading },
  productcategory: { productcategorys },
}) => {
  useEffect(() => {
    if (!projectcategorys) {
      getProjectcategorys({ limit: 100 });
    }
    if (!productcategorys) {
      getProductcategorys({ limit: 100 });
    }
  }, []);
  return (
    <div>
      <footer id="colophon" className="site-footer" role="contentinfo">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <ul>
                <h3>Main Menu</h3>
                <hr />
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/shop">Products</Link>
                </li>
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/our-team">Our Team</Link>
                </li>
                <li>
                  <Link to="/media">Media</Link>
                </li>
                <li>
                  <Link to="/contact"> Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3">
              <ul>
                <h3>Projects</h3>
                <hr />
                {projectcategorys &&
                  projectcategorys.map((item, index) => {
                    if (index < 7) {
                      return (
                        <li>
                          <Link to={`/category/${item.slug}`}>{item.name}</Link>
                        </li>
                      );
                    }
                  })}
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <h3>Products</h3>
                <hr />
                {productcategorys &&
                  productcategorys.map((item) => {
                    if (!item.parent_category) {
                      return (
                        <li>
                          <Link to={`/products/${item.slug}`}>{item.name}</Link>
                        </li>
                      );
                    }
                  })}
              </ul>
            </div>
            <div className="col-md-3">
              <ul>
                <h3>Contact Us</h3>
                <hr />
                <li>
                  <img src="/images/logo%20(4).png" />

                  <p>
                    <a
                      href="https://www.google.com/maps/place/Interior+Craft/@28.5225138,77.3935619,17z/data=!4m5!3m4!1s0x390ce9d48b8267cd:0xb8ae7c7899c59509!8m2!3d28.5233434!4d77.3962656"
                      target="_blank"
                    >
                      <i className="fa fa-map-marker"></i> A – 218,219,
                      Sector-83, Phase-II, Noida – 201305
                    </a>
                  </p>
                  <p>
                    <a href="mailto:info@interiorcraft.co.in" target="_blank">
                      <i className="fa fa-envelope"></i> info@interiorcraft.in
                    </a>
                  </p>
                  <p>
                    <a href="tel:+911203119001">
                      <i className="fa fa-phone"></i> Mobile :- +911203119001
                    </a>
                  </p>
                  <ul className="social-links">
                    <li>
                      <a
                        href="https://www.facebook.com/Interior-craft-384767484973127/"
                        target="_blank"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/interiorcraftnoida/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCv-HW7w9Wh6e9ER56XvcLvw"
                        target="_blank"
                      >
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        <div className="container">
          <div className="col-md-12">
            <div className="text-center"> Copyright © 2021 Interior Craft </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  projectcategory: state.projectcategory,
  productcategory: state.productcategory,
});

const mapDispatchToProps = { getProductcategorys, getProjectcategorys };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
