import {
  GET_PRODUCTCATEGORYS,
  TOTAL_PRODUCTCATEGORYS,
  ADD_PRODUCTCATEGORY,
  EDIT_PRODUCTCATEGORY,
  GET_PRODUCTCATEGORY,
  RESET_PRODUCTCATEGORY,
  PRODUCTCATEGORYS_ERROR,
} from "../actions/types";

const initialState = {
  productcategorys: null,
  productcategory: null,
  total_productcategorys: 0,
  loading: true,
  error: {},
  productcategory_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTCATEGORYS:
      return {
        ...state,
        productcategorys: payload,
        loading: false,
        error: {},
      };

    case TOTAL_PRODUCTCATEGORYS:
      return {
        ...state,
        total_productcategorys: payload,
        loading: false,
        error: {},
      };
    case RESET_PRODUCTCATEGORY:
      return {
        ...state,
        productcategorys: null,
        productcategory: null,
        loading: true,
        error: {},
      };

    case ADD_PRODUCTCATEGORY:
      return {
        ...state,
        productcategory_message: payload,
        loading: false,
        error: {},
      };
    case GET_PRODUCTCATEGORY:
      return {
        ...state,
        productcategory: payload,
        loading: false,
        error: {},
      };
    case EDIT_PRODUCTCATEGORY:
      return {
        ...state,
        productcategory_message: payload,
        loading: false,
        error: {},
      };

    case PRODUCTCATEGORYS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
