import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import Spinner from "../layout/Spinner";
import { projects } from "../modals/projects";
import Slider from "react-slick";
import Footer from "../components/Footer";
import { getProjectBySlug } from "../actions/projects";
import { URI } from "../constants/constants";

const SingleProject = ({
  match,
  history,
  getProjectBySlug,
  project: { loading, project },
}) => {
  const [activeItem, setActiveItem] = useState(0);

  const [showFullscreen, setShowFullscreen] = useState(false);
  useEffect(() => {
    if (match.params.slug) {
      setActiveItem(0);
      getProjectBySlug(match.params.slug);
    }
  }, [match]);
  const [offset, setOffset] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div>
      {!showFullscreen && <Link to="/" className="ui-logo" />}
      {!showFullscreen && <Menu />}
      <div className="desktop-only">
        {project ? (
          <section
            className="single-products"
            style={{ display: showFullscreen ? "none" : "block" }}
          >
            <div className="product-sidebar">
              <div className="product-title">{project.name}</div>
              <div className="all-categoris">
                <div>
                  <Link
                    to={`/category/${
                      project.project_category && project.project_category.slug
                    }`}
                  >
                    <i className="fa fa-filter"></i>{" "}
                    {project.project_category &&
                      `${project.project_category.name}`}{" "}
                  </Link>
                </div>
                {/* <div className="text-center pt-4">
                  <a
                    onClick={() => nextProduct()}
                    style={{ color: "rgba(235,92,20,0.7)" }}
                  >
                    <i className="fa fa-long-arrow-down"></i> NEXT PRODUCT
                  </a>
                </div> */}
              </div>
            </div>
            <div className="product-description">
              <div className="product-image">
                {project.gallery &&
                  project.gallery.map((photo, index) => {
                    return (
                      <img
                        src={`${URI}${photo.url}`}
                        key={`Interior Craft Product-${index}`}
                        alt="interior craft"
                        style={{
                          display: activeItem === index ? "block" : "none",
                        }}
                        onClick={() => setShowFullscreen(!showFullscreen)}
                      />
                    );
                  })}
              </div>
              {project.gallery.length > 1 && (
                <div className="next-arrow">
                  <img
                    src={"/images/right-arrow.png"}
                    alt="Interior Craft"
                    onClick={() =>
                      setActiveItem(
                        project.gallery.length - 1 > activeItem
                          ? activeItem + 1
                          : 0
                      )
                    }
                  />
                </div>
              )}
            </div>
          </section>
        ) : (
          <div>
            {" "}
            <Spinner />{" "}
          </div>
        )}
        {showFullscreen && project && (
          <section>
            <div className="full-screen-product">
              <div
                className="full-screen-image"
                onClick={() => setShowFullscreen(!showFullscreen)}
              >
                {project.gallery &&
                  project.gallery.map((photo, index) => {
                    return (
                      <img
                        src={`${URI}${photo.url}`}
                        key={`Interior Craft Product-${index}`}
                        alt="interior craft"
                        style={{
                          display: activeItem === index ? "block" : "none",
                        }}
                      />
                    );
                  })}
              </div>
              {project.gallery.length > 1 && (
                <div className="next-arrow">
                  <img
                    src={"/images/right-arrow.png"}
                    alt="Interior Craft"
                    onClick={() =>
                      setActiveItem(
                        project.gallery.length - 1 > activeItem
                          ? activeItem + 1
                          : 0
                      )
                    }
                  />
                </div>
              )}
            </div>
          </section>
        )}
      </div>
      <div className="mobile-only">
        {project && (
          <div>
            <div className="mobile-wrapper">
              <div className="mobile-product-title">{project.name}</div>
              <div>
                <div>
                  <Link
                    to={`/category/${
                      project.project_category && project.project_category.slug
                    }`}
                  >
                    <i className="fa fa-filter"></i>{" "}
                    {project.project_category &&
                      `${project.project_category.name}`}{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="mobile-product-image">
              <Slider {...settings}>
                {project.gallery &&
                  project.gallery.map((photo, index) => {
                    return (
                      <img
                        src={`${URI}${photo.url}`}
                        key={`Interior Craft Product-${index}`}
                        alt="interior craft"
                      />
                    );
                  })}
              </Slider>
            </div>
            <Footer />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ project: state.project });

const mapDispatchToProps = { getProjectBySlug };

export default connect(mapStateToProps, mapDispatchToProps)(SingleProject);
