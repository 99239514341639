import api from "../utils/api";
import {
  GET_PROJECTCATEGORYS,
  TOTAL_PROJECTCATEGORYS,
  ADD_PROJECTCATEGORY,
  GET_PROJECTCATEGORY,
  RESET_PROJECTCATEGORY,
  PROJECTCATEGORYS_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countProjectcategory =
  ({ q, searchParams, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      let query = "/project-categories/count?";
      if (q) {
        let newQ = ``;
        newQ += `&_q=${q}`;
        query = query + newQ;
      }
      if (searchParams && searchParams.length > 0) {
        let newQ = ``;
        searchParams.map((item) => {
          newQ += `&${item.label}=${item.value}`;
        });
        query = query + newQ;
      }
      if (createdAt_lte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
        query = query + newCreatedAt_gte;
      }
      if (createdAt_gte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
        query = query + newCreatedAt_gte;
      }

      let res = await api.get(query);
      dispatch({
        type: TOTAL_PROJECTCATEGORYS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PROJECTCATEGORYS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };

// Get posts
export const getProjectcategorys =
  ({
    limit = 10,
    searchParams,
    page = 1,
    sort = "DESC",
    q,
    createdAt_lte,
    createdAt_gte,
  }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_PROJECTCATEGORY,
      });
      if (page) {
        const start = (page - 1) * limit;
        let query = `/project-categories?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
        if (q) {
          const newQ = `&_q=${q}`;
          query = query + newQ;
        }
        if (searchParams && searchParams.length > 0) {
          let newQ = ``;
          searchParams.map((item) => {
            newQ += `&${item.label}=${item.value}`;
          });
          query = query + newQ;
        }
        if (createdAt_lte) {
          const newLTE = `&createdAt_lte=${createdAt_lte}`;
          query = query + newLTE;
        }
        if (createdAt_gte) {
          const newGTE = `&createdAt_gte=${createdAt_gte}`;
          query = query + newGTE;
        }

        const res = await api.get(query);

        dispatch({
          type: GET_PROJECTCATEGORYS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: PROJECTCATEGORYS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };
export const getProjectcategoryBySlug = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PROJECTCATEGORY,
    });
    const res = await api.get("/project-categories?slug=" + id);
    if (res.data && res.data.length > 0) {
      dispatch({
        type: GET_PROJECTCATEGORY,
        payload: res.data[0],
      });
    } else {
      dispatch(setAlert("No Projectcategory Found", "danger"));
    }
  } catch (err) {
    dispatch({
      type: PROJECTCATEGORYS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
// Get posts
export const getProjectcategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PROJECTCATEGORY,
    });
    const res = await api.get("/project-categories/" + id);

    dispatch({
      type: GET_PROJECTCATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROJECTCATEGORYS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const addProjectcategory = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/project-categories`, data);

    dispatch(setAlert("Projectcategory Saved!", "success"));
    dispatch({
      type: ADD_PROJECTCATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: PROJECTCATEGORYS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const editProjectcategory = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/project-categories/" + id, data);

    dispatch(setAlert("Projectcategory Updated!", "success"));
  } catch (err) {
    dispatch({
      type: PROJECTCATEGORYS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const deleteProjectcategory = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_PROJECTCATEGORY,
    });
    const res = await api.delete("/project-categories/" + id);

    dispatch(setAlert("Projectcategory Deleted!", "success"));
  } catch (err) {
    dispatch({
      type: PROJECTCATEGORYS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
