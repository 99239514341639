import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Menu from "../components/Menu";
import Sliders from "../components/Sliders";
import { getGallerys } from "../actions/gallerys";
import { URI } from "../constants/constants";
import Skeleton from "react-loading-skeleton";
import Spinner from "../layout/Spinner";

const ProductGallery = ({ getGallerys, gallery: { gallerys } }) => {
  useEffect(() => {
    getGallerys({ limit: 200 });
  }, []);
  return (
    <div>
      <Menu />
      <div style={{ background: "rgba(0,0,0,0.6)" }}>
        <section style={{ paddingTop: "100px" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="page-title text-center">Gallery</div>
              </div>
            </div>
          </div>
        </section>
        {gallerys ? (
          <div class="panel-list">
            {gallerys &&
              gallerys.map((item) => {
                return (
                  <div class="content">
                    <Link to={`/gallery/${item.slug}`}>
                      <div class="content-overlay"></div>
                      <img
                        class="content-image"
                        src={
                          item.gallery &&
                          item.gallery.length > 0 &&
                          `${URI}${item.gallery[0].url}`
                        }
                      />
                      <div class="content-details fadeIn-bottom">
                        <h3 class="content-title">{item.name}</h3>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        ) : (
          <div>
            <Spinner />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ gallery: state.gallery });

const mapDispatchToProps = { getGallerys };

export default connect(mapStateToProps, mapDispatchToProps)(ProductGallery);
