import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { CheckBox, SelectBox, TextArea, TextInput } from "../Form/Form";
import { addQuotation } from "../../actions/quotations";
const QuotationForm = ({
  openQuote,
  setOpenQuote,
  addQuotation,
  activeImage,
}) => {
  const [gallery, setGallery] = useState(null);
  return (
    <div
      className="quotation-form"
      style={{ display: openQuote ? "block" : "none" }}
    >
      <div className="get-free-quote-form">
        <div className="text-center">
          <h3>
            Get Quotation{" "}
            <span className="close-btn-wrapper">
              <button
                className="btn button-close"
                onClick={() => setOpenQuote(false)}
              >
                <i className="fa fa-close"></i>
              </button>
            </span>
          </h3>
          <p>
            Please fill the details below to get a quotation of selected item
          </p>
        </div>
        <hr />
        <div className="get-free-formm">
          <Formik
            initialValues={{
              name: "",
              phone: "",
              email: "",
              address: "",
              message: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Required"),
              phone: Yup.string().required("Required"),
              email: Yup.string().required("Required"),
              address: Yup.string().required("Required"),
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              const newForm = new FormData();
              if (gallery) {
                Array.from(gallery).forEach((image, index) => {
                  console.log("IMAGE", image);
                  newForm.append(`files.site_images`, image, image.name);
                });
              }
              values.selected_image = activeImage;
              newForm.append("data", JSON.stringify(values));
              await addQuotation(newForm);
              resetForm();
              setSubmitting(false);
            }}
          >
            {(formik) => {
              return (
                <Form autoComplete={"off"}>
                  <div className="form-group">
                    <TextInput label="Name" name="name" type="text" />
                  </div>
                  <div className="form-group">
                    <TextInput label="Phone" name="phone" type="text" />
                  </div>
                  <div className="form-group">
                    <TextInput label="Email" name="email" type="text" />
                  </div>

                  <div className="form-group">
                    <TextInput
                      label="Site Address"
                      name="address"
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label> Site Image </label>
                    <br />
                    <input
                      label="Address"
                      name="featured_image"
                      type="file"
                      className="form-control-file"
                      multiple
                      onChange={(e) => setGallery(e.target.files)}
                    />
                  </div>

                  <div className="form-group">
                    <TextArea label="Message" name="message" type="text" />
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-getfree">
                      Submit
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addQuotation };

export default connect(mapStateToProps, mapDispatchToProps)(QuotationForm);
