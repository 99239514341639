import React from "react";

function ProductVariation({
  product,
  setActiveImage,
  setVariation,
  setActiveSubMenu,
  variation_index,
  handleClick,
}) {
  return (
    <div>
      <div
        className="color-bg"
        style={{
          background: "rgba( 255, 255, 255, 0.6 )",
        }}
      >
        <div>
          <a
            href="#"
            onClick={() => {
              handleClick();
            }}
          >
            <span
              className="color1 hov color-bg-image"
              style={{
                backgroundImage: `url(${product.pic})`,
              }}
            >
              {/* {activeVariation ===
                                                            index && (
                                                            <i className="fa fa-check">
                                                              {" "}
                                                            </i>
                                                          )} */}
            </span>
            <div className="cc-c">
              <h4>{product.variation_name}</h4>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProductVariation;
