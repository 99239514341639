import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import Sliders from "../components/Sliders";
import { getProducts } from "../actions/products";
import { getProductcategorys } from "../actions/productcategorys";
import { URI } from "../constants/constants";
import Footer from "../components/Footer";
const Shop = ({
  getProductcategorys,
  productcategory: { loading, productcategorys },
}) => {
  useEffect(() => {
    getProductcategorys({ limit: 100 });
  }, []);
  return (
    <div>
      <Menu />
      <div
        className="shop-wrapper "
        style={{
          background: "rgba(0, 0, 0, 0.6)",
          padding: "100px 0px 0px 0px",
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="page-title text-center">Shop</div>
          </div>
        </div>
        <div className="row">
          {productcategorys &&
            productcategorys.map((product) => {
              if (!product.parent_category) {
                return (
                  <div className="col-md-4 no-padding">
                    <div className="content">
                      <Link to={`/products/${product.slug}`}>
                        <div className="content-overlay"></div>
                        <img
                          className="content-image"
                          src={
                            product.featured_image
                              ? `${URI}${product.featured_image.url}`
                              : "/images/IMG-20160705-WA0003.jpg"
                          }
                        />
                        <div className="content-details fadeIn-bottom">
                          <h3 className="content-title">{product.name}</h3>
                          <p className="content-text">
                            {product.short_description}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ productcategory: state.productcategory });

const mapDispatchToProps = { getProductcategorys };

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
