import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import CategoryGrid from "../components/homepage/CategoryGrid";
import HomeSlider from "../components/homepage/HomeSlider";
import Menu from "../components/Menu";
import Sliders from "../components/Sliders";

const Home = () => {
  return (
    <div>
      <Menu />
      <div className="desktop-only">
        <Sliders />
      </div>
      <div className="mobile-only">
        <HomeSlider />
      </div>

      <CategoryGrid />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
