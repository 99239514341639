import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCart } from "../actions/carts";
const Menu = ({ getCart, cart: { cart } }) => {
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    getCart();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="main-m-f">
        <div div className="header-bg">
          <div className="mt-2">
            {" "}
            <Link to="/" className="ui-logo">
              <img src="/images/logo (4).png" />
            </Link>
          </div>
          <div className="menu-btn" onClick={() => setOpenMenu(true)}>
            <span className="ui-menu">
              {" "}
              <i className="fa fa-bars"></i>{" "}
            </span>
          </div>
        </div>
        <div
          className="sidebar-menu"
          style={{ display: openMenu ? "block" : "none" }}
        >
          <div className="menu-position-relative">
            <div>
              <div className="menu-close" onClick={() => setOpenMenu(false)}>
                <i className="fa fa-close" />
              </div>
              <div className="menu__content">
                <ul>
                  <li>
                    <Link to="/">HOME</Link>
                  </li>
                  <li>
                    <Link to="/about">ABOUT</Link>
                  </li>

                  <li>
                    <Link to="/media">MEDIA</Link>
                  </li>
                  <li>
                    <Link to="/our-team">OUR TEAM</Link>
                  </li>
                  <li>
                    <Link to="/gallery">GALLERY</Link>
                  </li>

                  <li>
                    <Link to="/shop">SHOP</Link>
                  </li>
                  <li>
                    <Link to="/contact">CONTACT US</Link>
                  </li>
                  <li>
                    <a
                      href="http://adminpanel.interiorcraft.in/"
                      target="_blank"
                    >
                      TRACK PROJECT
                    </a>
                  </li>
                </ul>
                <ul className="social-links">
                  <li>
                    <a
                      href="https://www.facebook.com/Interior-craft-384767484973127/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/interiorcraftnoida/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCv-HW7w9Wh6e9ER56XvcLvw"
                      target="_blank"
                    >
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  cart: state.cart,
  alert: state.alert,
});

const mapDispatchToProps = { getCart };

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
