import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  CheckBox,
  SelectBox,
  TextArea,
  TextInput,
} from "../components/Form/Form";
import Footer from "../components/Footer";
import { addContact } from "../actions/contacts";
const ContactUs = ({ addContact }) => {
  return (
    <div>
      <Menu />
      <div className="wrapper-100">
        <div className="flex-contact-form">
          <div className="flex-contact-form-flex-1">
            <div className="contact-form">
              <div className="contact-title">Contact</div>
              <div className="contact-details">
                <p>Interior Craft </p>
                <p>A – 218,219, Sector-83, Phase-II,</p>
                <p> Noida – 201305</p>
                <p> Mobile :- +911203119001 </p>
              </div>
              <div className="contact-form-details">
                <Formik
                  initialValues={{
                    name: "",
                    phone: "",
                    email: "",
                    subject: "",
                    message: "",
                  }}
                  validationSchema={Yup.object({
                    name: Yup.string().required("Required"),
                    phone: Yup.string().required("Required"),
                    email: Yup.string().required("Required"),
                  })}
                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    await addContact(values);
                    resetForm();
                    setSubmitting(false);
                  }}
                >
                  {(formik) => {
                    console.log(formik);
                    return (
                      <Form>
                        <div className="row ">
                          <div className="col-md-6">
                            <TextInput
                              label="Name"
                              name="name"
                              type="text"
                              placeholder="Enter Name"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Phone"
                              name="phone"
                              type="text"
                              placeholder="Enter Phone"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Email"
                              name="email"
                              type="text"
                              placeholder="Enter Email"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextInput
                              label="Subject"
                              name="subject"
                              type="text"
                              placeholder="Enter Subject"
                            />
                          </div>
                          <div className="col-md-6">
                            <TextArea
                              label="Message"
                              name="message"
                              type="text"
                              placeholder="Enter Message"
                            />
                          </div>
                        </div>
                        <div className="contact-button-holder">
                          <button className="btn submit-contact-form">
                            Send
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
          <div className="flex-contact-form-flex-1">
            <a
              href="https://www.google.com/maps/place/Interior+Craft/@28.5225138,77.3935619,17z/data=!4m5!3m4!1s0x390ce9d48b8267cd:0xb8ae7c7899c59509!8m2!3d28.5233434!4d77.3962656"
              target="_blank"
            >
              <div className="contact-form-holder">
                <img src="/images/map.png" className="contact-form-image" />
                <div className="show-direction-wrapper">
                  <div className="get-direction-btn">
                    <a
                      className="quote-button"
                      href="https://goo.gl/maps/EXJrFBW8XsaFUgMd8"
                      target="_blank"
                    >
                      GET DIRECTION
                    </a>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { addContact };

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
