import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getMobilebanners } from "../../actions/mobilebanners";
import { URI } from "../../constants/constants";
import Slider from "react-slick";
const HomeSlider = ({
  getMobilebanners,
  mobilebanner: { loading, mobilebanners },
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);
  useEffect(() => {
    if (!mobilebanners) {
      getMobilebanners({ limit: 100 });
    }
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current, next) => setActiveSlide(next),
    afterChange: (current) => setActiveSlide2(current),
  };
  return (
    <div className="mobile-banners">
      <Slider {...settings}>
        {mobilebanners &&
          mobilebanners.map((slider) => {
            return (
              <div>
                <div className="slide-relative">
                  <img src={`${URI}${slider.featured_image.url}`} />
                  <div className="slider-cover">
                    <div className="slider-heading">{slider.heading}</div>
                    <div className="slider-content">{slider.content}</div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mobilebanner: state.mobilebanner,
});

const mapDispatchToProps = {
  getMobilebanners,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSlider);
