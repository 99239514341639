import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import Sliders from "../components/Sliders";
import { getProducts } from "../actions/products";
import { URI } from "../constants/constants";
import Footer from "../components/Footer";
import CategoryGrid from "../components/homepage/CategoryGrid";
const Projects = ({ getProducts, product: { loading, products } }) => {
  useEffect(() => {
    getProducts({ limit: 10 });
  }, []);
  return (
    <div>
      <Menu />
      <CategoryGrid />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ product: state.product });

const mapDispatchToProps = { getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
