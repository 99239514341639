import {
  GET_MOBILEBANNERS,
  TOTAL_MOBILEBANNERS,
  ADD_MOBILEBANNER,
  EDIT_MOBILEBANNER,
  GET_MOBILEBANNER,
  RESET_MOBILEBANNER,
  MOBILEBANNERS_ERROR,
} from "../actions/types";

const initialState = {
  mobilebanners: null,
  mobilebanner: null,
  total_mobilebanners: 0,
  loading: true,
  error: {},
  mobilebanner_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_MOBILEBANNERS:
      return {
        ...state,
        mobilebanners: payload,
        loading: false,
        error: {},
      };

    case TOTAL_MOBILEBANNERS:
      return {
        ...state,
        total_mobilebanners: payload,
        loading: false,
        error: {},
      };
    case RESET_MOBILEBANNER:
      return {
        ...state,
        mobilebanners: null,
        mobilebanner: null,
        loading: true,
        error: {},
      };

    case ADD_MOBILEBANNER:
      return {
        ...state,
        mobilebanner_message: payload,
        loading: false,
        error: {},
      };
    case GET_MOBILEBANNER:
      return {
        ...state,
        mobilebanner: payload,
        loading: false,
        error: {},
      };
    case EDIT_MOBILEBANNER:
      return {
        ...state,
        mobilebanner_message: payload,
        loading: false,
        error: {},
      };

    case MOBILEBANNERS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
