export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";

// Cart
export const GET_CART = "GET_CART";
export const CARTS_ERROR = "CARTS_ERROR";
// producsts
export const GET_PRODUCTS = "GET_PRODUCTS";
export const TOTAL_PRODUCTS = "TOTAL_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const GET_PRODUCT = "GET_PRODUCT";
export const RESET_PRODUCT = "RESET_PRODUCT";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
// Homepage Banners
export const GET_HOMEPAGEBANNERS = "GET_HOMEPAGEBANNERS";
export const TOTAL_HOMEPAGEBANNERS = "TOTAL_HOMEPAGEBANNERS";
export const ADD_HOMEPAGEBANNER = "ADD_HOMEPAGEBANNER";
export const EDIT_HOMEPAGEBANNER = "EDIT_HOMEPAGEBANNER";
export const GET_HOMEPAGEBANNER = "GET_HOMEPAGEBANNER";
export const RESET_HOMEPAGEBANNER = "RESET_HOMEPAGEBANNER";
export const HOMEPAGEBANNERS_ERROR = "HOMEPAGEBANNERS_ERROR";
// Project Categories
export const GET_PROJECTCATEGORYS = "GET_PROJECTCATEGORYS";
export const TOTAL_PROJECTCATEGORYS = "TOTAL_PROJECTCATEGORYS";
export const ADD_PROJECTCATEGORY = "ADD_PROJECTCATEGORY";
export const EDIT_PROJECTCATEGORY = "EDIT_PROJECTCATEGORY";
export const GET_PROJECTCATEGORY = "GET_PROJECTCATEGORY";
export const RESET_PROJECTCATEGORY = "RESET_PROJECTCATEGORY";
export const PROJECTCATEGORYS_ERROR = "PROJECTCATEGORYS_ERROR";
// Projects
export const GET_PROJECTS = "GET_PROJECTS";
export const TOTAL_PROJECTS = "TOTAL_PROJECTS";
export const ADD_PROJECT = "ADD_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const GET_PROJECT = "GET_PROJECT";
export const RESET_PROJECT = "RESET_PROJECT";
export const PROJECTS_ERROR = "PROJECTS_ERROR";
// Product Categorys
export const GET_PRODUCTCATEGORYS = "GET_PRODUCTCATEGORYS";
export const TOTAL_PRODUCTCATEGORYS = "TOTAL_PRODUCTCATEGORYS";
export const ADD_PRODUCTCATEGORY = "ADD_PRODUCTCATEGORY";
export const EDIT_PRODUCTCATEGORY = "EDIT_PRODUCTCATEGORY";
export const GET_PRODUCTCATEGORY = "GET_PRODUCTCATEGORY";
export const RESET_PRODUCTCATEGORY = "RESET_PRODUCTCATEGORY";
export const PRODUCTCATEGORYS_ERROR = "PRODUCTCATEGORYS_ERROR";
// Variations
export const GET_VARIATIONS = "GET_VARIATIONS";
export const TOTAL_VARIATIONS = "TOTAL_VARIATIONS";
export const ADD_VARIATION = "ADD_VARIATION";
export const EDIT_VARIATION = "EDIT_VARIATION";
export const GET_VARIATION = "GET_VARIATION";
export const RESET_VARIATION = "RESET_VARIATION";
export const VARIATIONS_ERROR = "VARIATIONS_ERROR";
// Gallery
export const GET_GALLERYS = "GET_GALLERYS";
export const TOTAL_GALLERYS = "TOTAL_GALLERYS";
export const ADD_GALLERY = "ADD_GALLERY";
export const EDIT_GALLERY = "EDIT_GALLERY";
export const GET_GALLERY = "GET_GALLERY";
export const RESET_GALLERY = "RESET_GALLERY";
export const GALLERYS_ERROR = "GALLERYS_ERROR";
// Quotation
export const GET_QUOTATIONS = "GET_QUOTATIONS";
export const TOTAL_QUOTATIONS = "TOTAL_QUOTATIONS";
export const ADD_QUOTATION = "ADD_QUOTATION";
export const EDIT_QUOTATION = "EDIT_QUOTATION";
export const GET_QUOTATION = "GET_QUOTATION";
export const RESET_QUOTATION = "RESET_QUOTATION";
export const QUOTATIONS_ERROR = "QUOTATIONS_ERROR";
// Contacts
export const GET_CONTACTS = "GET_CONTACTS";
export const TOTAL_CONTACTS = "TOTAL_CONTACTS";
export const ADD_CONTACT = "ADD_CONTACT";
export const EDIT_CONTACT = "EDIT_CONTACT";
export const GET_CONTACT = "GET_CONTACT";
export const RESET_CONTACT = "RESET_CONTACT";
export const CONTACTS_ERROR = "CONTACTS_ERROR";
// Mobile Banners
export const GET_MOBILEBANNERS = "GET_MOBILEBANNERS";
export const TOTAL_MOBILEBANNERS = "TOTAL_MOBILEBANNERS";
export const ADD_MOBILEBANNER = "ADD_MOBILEBANNER";
export const EDIT_MOBILEBANNER = "EDIT_MOBILEBANNER";
export const GET_MOBILEBANNER = "GET_MOBILEBANNER";
export const RESET_MOBILEBANNER = "RESET_MOBILEBANNER";
export const MOBILEBANNERS_ERROR = "MOBILEBANNERS_ERROR";
