import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../components/Menu";
import { projects } from "../modals/projects";
import { getProjects } from "../actions/projects";
import { URI } from "../constants/constants";
import Footer from "../components/Footer";
import Spinner from "../layout/Spinner";
const CategoryArchive = ({
  match,
  getProjects,
  project: { projects, loading },
}) => {
  useEffect(() => {
    if (match.params.slug) {
      getProjects({
        searchParams: [
          { label: "project_category.slug", value: match.params.slug },
        ],
      });
    }
  }, [match.params.slug]);

  return (
    <div>
      <Menu />

      {!loading ? (
        <div class="panel-list">
          {projects &&
            projects.map((item) => {
              return (
                <div class="content">
                  <Link to={`/projects/${item.slug}`}>
                    <div>
                      <div class="content-overlay"></div>
                      <img
                        class="content-image"
                        src={
                          item.gallery && item.gallery[0]
                            ? `${URI}${item.gallery[0].url}`
                            : "/images/IMG-20160705-WA0003.jpg"
                        }
                      />
                      <div class="content-details fadeIn-bottom">
                        <h3 class="content-title">{item.name}</h3>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      ) : (
        <div
          style={{ width: "100%", padding: "100px 0px" }}
          className="text-center"
        >
          <Spinner />
        </div>
      )}

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({ project: state.project });

const mapDispatchToProps = { getProjects };

export default connect(mapStateToProps, mapDispatchToProps)(CategoryArchive);
